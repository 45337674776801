<template>
  <div class="pages">
    <div id="map"></div>
    <div class="content-wrapper">
      <div class="top">
        <div class="item" v-for="(item,index) in typeList" :key="index" @click="handleTypeClick(index)">
          <el-image :src="typeIndex === index?item.activeIcon:item.defaultIcon" fit="cover"/>
          <span>{{ item.name }}</span>
        </div>
      </div>
      <el-scrollbar class="bottom" v-loading="typeListLoading">
        <div class="item" v-for="item in typeList[typeIndex].poiList" :key="item.id">
          <div class="con-top">
            <div class="left">
              <el-image src="/static/images/location.png" fit="cover"/>
              <span>{{ item.title }}</span>
            </div>
            <span class="right">{{ item._distance }}米</span>
          </div>
          <div class="con-bottom">
            {{ item.address }}
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
let map = null // eslint-disable-line
export default {
  name: 'HousesSurrounding',
  data() {
    return {
      project: false,
      typeListLoading: false,
      typeIndex: 0,
      typeList: [
        {
          name: '交通',
          keyword: '公交',
          defaultIcon: '/static/images/jcts.png',
          activeIcon: '/static/images/jcts-active.png',
          poiList: []
        },
        {
          name: '商业',
          keyword: '商超',
          defaultIcon: '/static/images/uhye.png',
          activeIcon: '/static/images/uhye-active.png',
          poiList: []
        },
        {
          name: '教育',
          keyword: '学校',
          defaultIcon: '/static/images/jcyu.png',
          activeIcon: '/static/images/jcyu-active.png',
          poiList: []
        },
        {
          name: '医疗',
          keyword: '医院',
          defaultIcon: '/static/images/yilc.png',
          activeIcon: '/static/images/yilc-active.png',
          poiList: []
        },
      ]
    }
  },
  async mounted() {
    await this.fetchData()
    this.initMap()
    this.fetchExplore()
  },
  methods: {
    initMap() {
      var center = new window.TMap.LatLng(this.project.base_data.lat, this.project.base_data.lng)
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      map = new window.TMap.Map(document.getElementById('map'), {
        center: center,//设置地图中心点坐标
        zoom: 17.2,   //设置地图缩放级别
        pitch: 43.5,  //设置俯仰角
        rotation: 45    //设置地图旋转角度
      })
      // 点 标记
      var markerLayer = new window.TMap.MultiMarker({
        map: map,  //指定地图容器
        id: 'marker-layer',
      })

      markerLayer.add({
        id: 'dot',
        position: new window.TMap.LatLng(this.project.base_data.lat, this.project.base_data.lng)
      })
    },
    async fetchData() {
      let res = await this.http({
        url: '/api/report/projectInfo',
        method: 'GET',
        params: {
          project_id: this.$route.query.id
        }
      })

      this.project = res.data
    },
    async fetchExplore() {
      this.typeListLoading = true
      if (this.typeList[this.typeIndex].poiList.length === 0) {
        try {
          let res = await this.http({
            url: '/ws/place/v1/search',
            method: 'GET',
            params: {
              key: 'UGBBZ-IL364-ILOUZ-XHPZK-Y22MS-XNBXY',
              boundary: `nearby(${this.project.base_data.lat},${this.project.base_data.lng},1000,1)`,
              keyword: this.typeList[this.typeIndex].keyword,
              page_index: 1,
              page_size: 10,
              orderby: '_distance',
              output: 'json',
            }
          })

          this.typeList[this.typeIndex].poiList = res.data
        } catch (e) {
          console.log(e)
        } finally {
          this.typeListLoading = false
        }
      }
    },
    handleTypeClick(e) {
      this.typeIndex = e
      this.fetchExplore()
    }
  }
}
</script>

<style scoped lang="scss">
@import "HousesSurrounding";
</style>
